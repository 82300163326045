import { http, parseError, hsAgiHttp } from "@/utils"
import store from "@/store"
import { COMMITS, GETTERS } from "@/store/constants"
import { eventBus } from "@/plugins/eventBus.plugin"
import uiEvents from "@/configs/uiEvents"

export const getThreadById = async (id) => {
  const res = await (await hsAgiHttp()).get(`/v1/agi/thread/${id}`)
  return res.data.data
}

export const updateAgentChat = async (id, payload) => {
  try {
    const res = await (await http()).put(`/agent/chats/${id}`, payload)
    if (!res.data.chat) {
      throw new Error("Unable to modify the chat histories")
    }

    const chatHistories = store.getters[GETTERS.AGENT_CHAT_HISTORY]
    const filteredChats = chatHistories.filter((c) => c.id !== id)

    filteredChats.push(res.data.chat)
    store.commit(COMMITS.SET_AGENT_CHAT_HISTORY, filteredChats)

    eventBus.emit(uiEvents.GLOBAL.SHOW_TOAST, {
      severity: "error",
      text: "Successfully updated the chat",
    })
  } catch (err) {
    window.devErr(err)
    eventBus.emit(uiEvents.GLOBAL.SHOW_TOAST, {
      severity: "error",
      text: parseError(err),
    })
  }
}

export async function deleteThread(id) {
  try {
    const user = store.getters[GETTERS.USER]
    if (!user) {
      throw new Error("No logged in user found")
    }
    const res = await (
      await hsAgiHttp()
    ).delete(`/v1/agi/threads/${user._id}/${id}`)
    if (!res.data.success) throw new Error(res.data.message)

    eventBus.emit(uiEvents.GLOBAL.SHOW_TOAST, {
      severity: "success",
      text: "Thread deleted",
    })
  } catch (err) {
    window.devErr(err)
    eventBus.emit(uiEvents.GLOBAL.SHOW_TOAST, {
      severity: "error",
      text: parseError(err),
    })
  }
}
